import React, { useState } from "react";
import {
    Videoueberwachung,
    Alarmanlage,
    Tuerschloss,
} from "./PriceCalculator/elements/Constants";
import { Link } from "gatsby";

const KarriereComp6 = ({ product }) => {
    const getWidth = () => document.getElementById("scrollBox2").offsetWidth;

    const [state, setState] = useState({
        activeIndex: 0,
    });

    let products = [
        {
            title: "TEAMGEIST",
            subTitle: ".",
            description:
                "Wir arbeiten gemeinsam, um unsere Ziele zu erreichen und unterstützen uns gegenseitig. Dabei wollen wir effektiv kommunizieren und kooperieren. Gemeinsam arbeiten wir für den Erfolg des Kunden!",
            image: "/img/carrier/pexels-pavel-danilyuk-6340700.jpg",
            alt: "TEAMGEIST",
        },
        {
            title: "GRÜNDLICHKEIT",
            subTitle: ".",
            description:
                "Wir haben den Anspruch, sorgfältig und präzise zu arbeiten und sicherzustellen, dass alle Aufgaben ordnungsgemäß erledigt werden. Wir sind uns bewusst, dass diese Gründlichkeit für die Sicherheit unserer Kunden erforderlich ist.",
            image: "/img/carrier/pexels-david-bartus-295885 v2.jpg",
            alt: "GRÜNDLICHKEIT",
        },
        {
            title: "BEGEISTERUNG",
            subTitle: ".",
            description:
                "Wir arbeiten mit Leidenschaft und Energie an unseren Projekten und sind motiviert, uns für erfolgreiche Projekte zu engagieren. Wir transportieren diese Begeisterung täglich zu unseren Kunden und auch zu unseren Kollegen. ",
            image: "/img/carrier/pexels-sebastian-arie-voortman-214574.jpg",
            alt: "BEGEISTERUNG",
        },
        {
            title: "VERANTWORTUNG",
            subTitle: ".",
            description:
                "Wir sind als Team dafür verantwortlich, dass die Sicherheitssysteme unserer Kunden optimal funktionieren. Wir sind alle „Unternehmer im Unternehmen“ und handeln auch entsprechend.",
            image: "/img/carrier/pexels-akil-mazumder-1072824.jpg",
            alt: "VERANTWORTUNG",
        },
    ];

    const { activeIndex } = state;

    const handleArrowClick = (right, width) => {
        console.log("right:", right, "width:", width);
        if (right) {
            if (activeIndex === products.length - 1) {
                document.getElementById("scrollBox2").scrollLeft -=
                    activeIndex * width;
            } else {
                document.getElementById("scrollBox2").scrollLeft += width;
            }
        } else {
            if (activeIndex === 0) {
                document.getElementById("scrollBox2").scrollLeft +=
                    (products.length - 1) * width;
            } else {
                document.getElementById("scrollBox2").scrollLeft -= width;
            }
        }
    };

    const handleDotClick = (newIndex) => {
        console.log("handleDotClick fired :", newIndex);
        let actualIndex = Math.round(
            document.getElementById("scrollBox2").scrollLeft / getWidth()
        );
        let amountOfScroll = newIndex - actualIndex;
        if (amountOfScroll !== 0) {
            document.getElementById("scrollBox2").scrollLeft +=
                amountOfScroll * getWidth();
        }
    };

    const listenScrollEvent = () => {
        let newActiveIndex = Math.round(
            document.getElementById("scrollBox2").scrollLeft /
                document.getElementById("scrollBox2").offsetWidth
        );
        if (newActiveIndex !== activeIndex) {
            setState({
                ...state,
                activeIndex: Math.round(
                    document.getElementById("scrollBox2").scrollLeft /
                        document.getElementById("scrollBox2").offsetWidth
                ),
            });
        }
    };

    return (
        <div
            className="dim-change2"
            style={{ backgroundColor: "#f2f2f2", paddingBottom: "2rem" }}
        >
            <div className="slider has-text-centered ">
                <div className="how-to2">
                    <h2
                        className="title"
                        style={{
                            paddingTop: "2rem",
                            // paddingBottom: "2rem",
                        }}
                    >
                        Unsere Werte
                    </h2>
                    <p
                        style={{
                            // paddingTop: "2rem",
                            paddingBottom: "2rem",
                        }}
                    >
                        Unsere Mission ist es, Menschen ein{" "}
                        <b>Sicherheitsgefühl zu geben</b> und sie, ihren Besitz
                        sowie ihre Liebsten durch professionelle und modernste
                        Sicherheitssysteme abzusichern. Wir wollen unsere
                        <b> Kunden begeistern</b> und{" "}
                        <b>geben alles für ihre Sicherheit!</b>
                    </p>
                </div>
                <div className="columns is-vcentered m-0">
                    <div className="column has-text-right arrow arrow-hide-mobile">
                        <i
                            className="fas is-size-2 fa-arrow-circle-left"
                            onClick={() => handleArrowClick(false, getWidth())}
                        />
                    </div>
                    <div
                        id="scrollBox2"
                        onScroll={listenScrollEvent}
                        className="carousel column is-three-quarters dim-change"
                    >
                        {products.map((slide, i) => (
                            <div
                                key={slide + i}
                                id={slide.title}
                                className="carousel__item"
                            >
                                <div className="columns is-vcentered carousel__content">
                                    <div className="column level m-0">
                                        <div className="level-right m-0">
                                            <img
                                                src={slide.image}
                                                alt={slide.alt}
                                                className="carousel__image level-item"
                                            />
                                        </div>
                                    </div>
                                    <div className="column has-text-left-tablet carousel__text">
                                        <h2 className="title">{slide.title}</h2>
                                        <p>{slide.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="column has-text-left arrow arrow-hide-mobile">
                        <i
                            className="fas is-size-2 fa-arrow-circle-right"
                            onClick={() => handleArrowClick(true, getWidth())}
                        />
                    </div>
                </div>
                <div className="dots">
                    {products.map((product, i) => (
                        <i
                            key={i}
                            className="fas fa-circle"
                            onClick={() => handleDotClick(i)}
                            style={{
                                marginRight: "5px",
                                cursor: "pointer",
                                color:
                                    i === activeIndex ? "#777f8e" : "#e3e8ee",
                            }}
                        />
                    ))}
                </div>
            </div>
            <div
                className="centerButtonInDiv "
                style={{ paddingBottom: "25px" }}
            >
                <Link
                    className="button preisrechner-btn preisrechner-btn-primary newHome3Button"
                    to="/stellenanzeigen"
                >
                    Jetzt bewerben
                </Link>
            </div>
        </div>
    );
};

export default KarriereComp6;
