import React, { useState } from "react";
import {
    Videoueberwachung,
    Alarmanlage,
    Tuerschloss,
} from "./PriceCalculator/elements/Constants";
import { Link } from "gatsby";

const KarriereComp3 = ({ product }) => {
    const getWidth = () => document.getElementById("scrollBox").offsetWidth;

    const [state, setState] = useState({
        activeIndex: 0,
    });

    let products = [
        {
            title: "Ralf",
            subTitle: "Projektleiter Sicherheitsberatung",
            description:
                "Die professionellen Abläufe bei MeinAlarm24 und die hohe Kundenorientierung begeistern mich täglich neu. Der Arbeitsalltag ist durch die unterschiedlichen Bedürfnisse und Anforderungen der Kunden sehr abwechslungsreich. Bei MeinAlarm24 ziehen über alle Abteilungen weg alle an einem Strang, um die Kundenwünsche zu erfüllen. ",
            image: "/img/carrier/Ralf Wally Karriere.jpg",
            alt: "Ralf",
        },
        {
            title: "Timo",
            subTitle: "Senior Projektleiter Sicherheitstechnik",
            description:
                "MeinAlarm24 gibt mir jeden Tag die Chance, mich selbst und meine Ideen einzubringen. Dabei bekomme ich stets das Gefühl vermittelt, dass Ideen gerne gehört und angenommen werden. Die Weiterentwicklungschancen sind groß und das Arbeiten macht auch aufgrund des familiären Feelings Spaß!",
            image: "/img/carrier/Timo Striedinger Karriere.jpg",
            alt: "Timo",
        },
        {
            title: "Cigdem",
            subTitle: "Buchhalterin",
            description:
                "Mit MeinAlarm24 habe ich ein familienfreundliches Unternehmen gefunden und kann so Familie und Beruf unter einen Hut bringen. Während der täglichen Arbeit zeichnet sich MeinAlarm24 insbesondere durch die netten und hilfsbereiten Kollegen und Kolleginnen aus, die für ein großartiges Arbeitsklima sorgen.",
            image: "/img/carrier/Cigdem Ohms Karriere.jpg",
            alt: "Cigdem",
        },
        {
            title: "Karl",
            subTitle: "Chief Sales Officer",
            description:
                "Ich arbeite gerne bei MeinAlarm24, weil wir ein tolles Team sind, in das jeder seine persönlichen Stärken und neue Ideen einbringt. Eine flache Hierarchie und eine offene und transparente Kommunikation auf Augenhöhe, sowie das Vertrauen in meine Arbeit motivieren mich jeden Tag, für MeinAlarm24 und das Team mein Bestes zu geben.",
            image: "/img/carrier/Karl Beck Karriere.jpg",
            alt: "Karl",
        },
    ];

    const { activeIndex } = state;

    const handleArrowClick = (right, width) => {
        if (right) {
            if (activeIndex === products.length - 1) {
                document.getElementById("scrollBox").scrollLeft -=
                    activeIndex * width;
            } else {
                document.getElementById("scrollBox").scrollLeft += width;
            }
        } else {
            if (activeIndex === 0) {
                document.getElementById("scrollBox").scrollLeft +=
                    (products.length - 1) * width;
            } else {
                document.getElementById("scrollBox").scrollLeft -= width;
            }
        }
    };

    const handleDotClick = (newIndex) => {
        let actualIndex = Math.round(
            document.getElementById("scrollBox").scrollLeft / getWidth()
        );
        let amountOfScroll = newIndex - actualIndex;
        if (amountOfScroll !== 0) {
            document.getElementById("scrollBox").scrollLeft +=
                amountOfScroll * getWidth();
        }
    };

    const listenScrollEvent = () => {
        let newActiveIndex = Math.round(
            document.getElementById("scrollBox").scrollLeft /
                document.getElementById("scrollBox").offsetWidth
        );
        if (newActiveIndex !== activeIndex) {
            setState({
                ...state,
                activeIndex: Math.round(
                    document.getElementById("scrollBox").scrollLeft /
                        document.getElementById("scrollBox").offsetWidth
                ),
            });
        }
    };

    return (
        <div className="dim-change">
            <div className="slider has-text-centered">
                <div className="how-to2">
                    <h2
                        className="title"
                        style={{
                            paddingTop: "2rem",
                            // paddingBottom: "2rem",
                        }}
                    >
                        Das sagen unsere Kolleginnen und Kollegen
                    </h2>
                    <p
                        style={{
                            // paddingTop: "2rem",
                            paddingBottom: "2rem",
                        }}
                    >
                        Erfahre mehr über unser Team, unser Arbeitsumfeld und
                        deine Karrieremöglichkeiten bei uns.
                    </p>
                </div>
                <div className="columns is-vcentered m-0">
                    <div className="column has-text-right arrow arrow-hide-mobile ">
                        <i
                            className="fas is-size-2 fa-arrow-circle-left"
                            onClick={() => handleArrowClick(false, getWidth())}
                        />
                    </div>
                    <div
                        id="scrollBox"
                        onScroll={listenScrollEvent}
                        className="carousel column is-three-quarters"
                    >
                        {products.map((slide, i) => (
                            <div
                                key={slide + i}
                                id={slide.title}
                                className="carousel__item"
                            >
                                <div className="columns is-vcentered carousel__content">
                                    <div className="column level m-0">
                                        <div className="level-right m-0">
                                            <img
                                                src={slide.image}
                                                alt={slide.alt}
                                                className="carousel__image level-item"
                                            />
                                        </div>
                                    </div>
                                    <div className="column has-text-left-tablet carousel__text">
                                        <h2 className="title title23">
                                            {slide.title}
                                        </h2>
                                        <h3 style={{ marginBottom: "1rem" }}>
                                            {slide.subTitle}
                                        </h3>
                                        <p>{slide.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="column has-text-left arrow arrow-hide-mobile ">
                        <i
                            className="fas is-size-2 fa-arrow-circle-right"
                            onClick={() => handleArrowClick(true, getWidth())}
                        />
                    </div>
                </div>
                <div className="dots">
                    {products.map((product, i) => (
                        <i
                            key={i}
                            className="fas fa-circle"
                            onClick={() => handleDotClick(i)}
                            style={{
                                marginRight: "5px",
                                cursor: "pointer",
                                color:
                                    i === activeIndex ? "#777f8e" : "#e3e8ee",
                            }}
                        />
                    ))}
                </div>
                <div
                    className="centerButtonInDiv "
                    style={{ paddingBottom: "25px" }}
                >
                    <Link
                        className="button preisrechner-btn preisrechner-btn-primary newHome3Button"
                        to="/stellenanzeigen"
                    >
                        Jetzt bewerben
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default KarriereComp3;
