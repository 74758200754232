import React from "react";
import { Link } from "gatsby";

export default class KarriereComp1 extends React.Component {
    render() {
        return (
            // <div style={{ backgroundColor: "#F2F2F2" }}>
            <div>
                <div
                    className="section how-to2"
                    style={{
                        marginTop: "40px",
                        paddingLeft: "30px",
                        paddingRight: "10px",
                    }}
                >
                    <div className="body">
                        <div className="columns">
                            <div className="column has-text-centered">
                                <h2 className="title custm-text">
                                    Herzlich willkommen bei MeinAlarm24!
                                </h2>
                            </div>
                        </div>
                        <div>
                            <div className="text-block-product">
                                <h3>
                                    Wir sind ein innovatives Startup, das sich
                                    auf Sicherheitstechnik spezialisiert hat.
                                </h3>
                                <br />
                                <p>
                                    Wir sind immer auf der Suche nach
                                    engagierten und talentierten Mitarbeitern,
                                    die mit uns gemeinsam unsere Kunden optimal
                                    schützen und die Sicherheitsbranche
                                    revolutionieren wollen. Bei MeinAlarm24 bist
                                    du kein kleines Rädchen im Getriebe - du
                                    bist ein wichtiger Teil unseres Teams! Deine
                                    Arbeit hat tagtäglich einen direkten
                                    Einfluss auf die Sicherheit unserer Kunden.
                                    Wenn du bereit bist, dich mit uns in eine
                                    vielversprechende Zukunft zu stürzen, dann
                                    bewirb dich jetzt und werde Teil unseres
                                    Teams!
                                </p>
                            </div>

                            <div className="text-block-product">
                                <h3>
                                    Wir freuen uns darauf, dich kennenzulernen
                                    und gemeinsam Großes zu erreichen!
                                </h3>
                            </div>

                            <div>
                                <div className="centerButtonInDiv">
                                    <Link
                                        className="button preisrechner-btn preisrechner-btn-primary newHome3Button"
                                        to="/stellenanzeigen/"
                                        id="btn_ vorteilealarm_mehrerfahren"
                                    >
                                        Jetzt bewerben
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
