import React, { useState } from "react";
import {
    Videoueberwachung,
    Alarmanlage,
    Tuerschloss,
} from "./PriceCalculator/elements/Constants";
import { Link } from "gatsby";

const KarriereComp4 = ({ product }) => {
    return (
        <div style={{ backgroundColor: "#F2F2F2" }}>
            <div className="how-to2" style={{ backgroundColor: "#F2F2F2" }}>
                <h2
                    className="title"
                    style={{
                        paddingTop: "2rem",
                        paddingBottom: "2rem",
                        textAlign: "center",
                    }}
                >
                    Unser Recruitingprozess
                </h2>

                <div className="only-mobile429">
                    <img src="/img/carrier/Career Steps Mobile Final.jpg" />
                </div>
                <div className="only-desktop429">
                    <img src="/img/carrier/Career Steps Final.jpg" />
                </div>

                <div
                    className="centerButtonInDiv "
                    style={{ paddingBottom: "25px" }}
                >
                    <Link
                        className="button preisrechner-btn preisrechner-btn-primary newHome3Button"
                        to="/stellenanzeigen"
                    >
                        Jetzt bewerben
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default KarriereComp4;
