import React from "react";
import { Link } from "gatsby";
const TickBlue = "/img/homepage/icons8-done-30.png";
const img1 = "/img/carrier/Benefit_Icon_01.svg";
const img2 = "/img/carrier/Benefit_Icon_02.svg";
const img3 = "/img/carrier/Benefit_Icon_03.svg";
const img4 = "/img/carrier/Benefit_Icon_04.svg";
const img5 = "/img/carrier/Benefit_Icon_05.svg";
const img6 = "/img/carrier/Benefit_Icon_06.svg";
const img7 = "/img/carrier/Benefit_Icon_07.svg";
const img8 = "/img/carrier/Benefit_Icon_08.svg";
const img9 = "/img/carrier/Benefit_Icon_09.svg";
const img10 = "/img/carrier/Benefit_Icon_10.svg";
const img11 = "/img/carrier/Benefit_Icon_11.svg";
const img12 = "/img/carrier/Benefit_Icon_12.svg";
const img13 = "/img/carrier/Benefit_Icon_13.svg";
function ListItem(props) {
    return (
        <p style={{ marginTop: "12px" }}>
            <img
                src={props.img}
                style={{
                    width: "35px",
                    height: "35px",
                    verticalAlign: "middle",
                    display: "block",
                    float: "left",
                }}
            />
            <span
                style={{
                    position: "relative",
                    // top: "-7px",
                    verticalAlign: "middle",
                    left: "15px",
                    fontSize: "20px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    // webkitLineClamp: "3",
                    webkitBoxOrient: "vertical",
                }}
            >
                <b className="font-chng"> {props.text}</b>
            </span>
        </p>
    );
}
export default class NewHome3 extends React.Component {
    render() {
        return (
            <div>
                <div
                    className="section how-to2"
                    // style={{ marginTop: "40px", backgroundColor: "#F2F2F2" }}
                >
                    <div className="body MeinAlarm24-Benefits">
                        <div className="columns">
                            <div className="column has-text-centered">
                                <h2 className="title">
                                    {/* Ihr professioneller Partner für Sicherheit.
                                    Ein Leben lang. */}
                                    MeinAlarm24-Benefits
                                </h2>
                                <h3>
                                    Wir wollen ein Arbeitgeber sein, der seine
                                    Mitarbeiter begeistert! Daher arbeiten wir
                                    täglich daran, ein Arbeitsumfeld zu
                                    schaffen, das es dir ermöglicht, mit Spaß
                                    und Begeisterung an tollen Projekten zu
                                    arbeiten.
                                </h3>
                            </div>
                        </div>

                        <div>
                            <div className="columns">
                                <div className="column has-text-centered">
                                    <div className="card">
                                        <div
                                            style={{
                                                marginTop: "10px",
                                                marginBottom: "10px",
                                                textAlign: "initial",
                                                marginLeft: "20px",
                                            }}
                                        >
                                            <ListItem
                                                img={img1}
                                                text="Höchste Gehälter in der Branche"
                                            />
                                            <ListItem
                                                img={img2}
                                                text="Attraktiver Firmenwagen (z.B. Tesla)"
                                            />
                                            <ListItem
                                                img={img3}
                                                text="Unbefristeter Arbeitsvertrag"
                                            />
                                            <ListItem
                                                img={img4}
                                                text="Flexible Arbeitszeiten"
                                            />
                                            <ListItem
                                                img={img5}
                                                text="30 Tage Urlaub"
                                            />
                                            <ListItem
                                                img={img6}
                                                text="Individuelle Karrierewege"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="column ">
                                    <div
                                        className="card"
                                        style={{ marginLeft: "20px" }}
                                    >
                                        <ListItem
                                            img={img7}
                                            text="Homeoffice möglich"
                                        />
                                        <ListItem
                                            img={img8}
                                            text="Vielfältige Weiterentwicklungsmöglichkeiten"
                                        />
                                        {/* <ListItem
                                            img={img9}
                                            text="Fahrradleasing"
                                        /> */}
                                        <ListItem
                                            img={img10}
                                            text="Familienfreundliches Unternehmen"
                                        />
                                        <ListItem
                                            img={img11}
                                            text="Offene Feedbackkultur"
                                        />
                                        <ListItem
                                            img={img12}
                                            text="Buddy- & Mentorenprogramm"
                                        />
                                        <ListItem
                                            img={img13}
                                            text="Teamevents"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="centerButtonInDiv">
                                <Link
                                    className="button preisrechner-btn preisrechner-btn-primary newHome3Button"
                                    to="/stellenanzeigen/"
                                >
                                    Jetzt bewerben
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
