import React, { useEffect } from "react";
import SevenDaysBanner from "../components/SevenDaysBanner";
import DefaultHero from "../components/DefaultHero";
import PriceCalculationButton from "../components/PriceCalculator/PriceCalculationButton";
import Alarmanlagen from "../components/Alarmanlagen";
import Footer from "../components/Footer";
import OurTeam from "../components/OurTeam";
import { StaticQuery, graphql } from "gatsby";
import HowTo2 from "../components/HowTo2";

import NewHome_We_Secure from "../components/NewHome_We_Secure";
import NewHome2 from "../components/NewHome2";
import NewHome3 from "../components/NewHome3";
import FaceToFace from "../components/FaceToFace";
import IntrusionAtlas from "../components/IntrusionAtlas";
import Testimonials from "../components/Testimonials";
import OurPartner from "../components/OurPartner";
import FloorPlan from "../components/FloorPlan";
import Kooperationspartner from "./kooperationspartner";

// new components in karrier

import KarriereComp1 from "../components/KarriereComp1";
import KarriereComp2 from "../components/KarriereComp2";
import KarriereComp3 from "../components/KarriereComp3";
import KarriereComp4 from "../components/KarriereComp4";
import KarriereComp5 from "../components/KarriereComp5";
import KarriereComp6 from "../components/KarriereComp6";
import KarriereComp7 from "../components/KarriereComp7";

export const HeroElement = () => {
    return (
        <div className="homepage carrier">
            <h1 className="top" style={{ color: "white" }}>
                Ihr kostenloser Alarmanlagen-Preisrechner
            </h1>
            <span
                className="middle"
                style={{ color: "white", marginTop: "-20px" }}
            >
                In nur wenigen Minuten Preis berechnen
                {/* In wenigen Minuten zur Preisindikation */}
            </span>
            {/* <PriceCalculationButton id="btn_bighero_preisberechnen" /> */}
        </div>
    );
};

export default () => {
    console.log(" index layout fired with ", Math.random() * 1000);
    return (
        <StaticQuery
            query={graphql`
                query KarriereQuery {
                    site {
                        siteMetadata {
                            title
                            image
                            image768
                            image1024
                            image1216
                            image1408
                        }
                    }
                }
            `}
            render={(data) => {
                const contextData = {
                    // title: data.site.siteMetadata.title,
                    image: "/img/hero/" + data.site.siteMetadata.image,
                    image768: "/img/hero/" + data.site.siteMetadata.image768,
                    image1024: "/img/hero/" + data.site.siteMetadata.image1024,
                    image1216: "/img/hero/" + data.site.siteMetadata.image1216,
                    image1408: "/img/hero/" + data.site.siteMetadata.image1408,
                    // showPriceCalculator: false,
                    showPriceCalculator: true,
                    showHeroMobile: false,
                    showBottom: true,
                    showElement: <HeroElement />,
                };
                console.log("contextData is ", contextData);
                return (
                    <div id="indexMain carrier">
                        <div className="section our-team2">
                            {/* <div className="static-hero"> */}
                            <div className="body">
                                <div className="content-desktop only-desktop has-text-centered">
                                    <div className="top-title-center">
                                        <h1 className="top">
                                            Werde Teil unseres unschlagbaren
                                            Teams.
                                        </h1>
                                        <h2>Bewirb dich jetzt!</h2>
                                    </div>
                                </div>
                                <div className="content-mobile only-mobile has-text-centered">
                                    <div className="top-title-center">
                                        <h1 className="top">
                                            Werde Teil unseres unschlagbaren
                                            Teams.
                                        </h1>
                                        <h2>Bewirb dich jetzt!</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <KarriereComp1 />
                        <KarriereComp2 />
                        <KarriereComp3 />
                        <KarriereComp4 />
                        <KarriereComp5 />
                        <KarriereComp6 />
                        <KarriereComp7 />
                        {/* <NewHome3 />

                        <HowTo2 />
                        <OurPartner />
                        <FloorPlan />
                        <NewHome2 /> */}

                        {/* <FaceToFace />
                        <Testimonials />
                        <OurTeam />
                        <Alarmanlagen /> */}
                        {/* <IntrusionAtlas context={contextData} /> */}
                        {/* <SevenDaysBanner /> */}
                        <Footer />
                    </div>
                );
            }}
        />
    );
};
