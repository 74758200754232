import React from "react";
import { Link } from "gatsby";

export default class KarriereComp2 extends React.Component {
    render() {
        return (
            <div
                className="karriercom"
                style={{ marginBottom: "20px", backgroundColor: "#F2F2F2" }}
            >
                {" "}
                <div
                    className="section how-to2 is-hidden-mobile "
                    style={{ paddingTop: "40px" }}
                >
                    <div className="body columns-padding-0">
                        <div className="columns">
                            <div className="column has-text-centered">
                                <h2
                                    className="title"
                                    style={{
                                        marginBottom: "3rem",
                                        marginTop: "1.5rem",
                                    }}
                                >
                                    Bei uns kannst du in folgenden Bereichen
                                    durchstarten:
                                </h2>
                                {/* <h3>Alles für Ihre Sicherheit.</h3> */}
                            </div>
                        </div>

                        <div>
                            <div className="columns">
                                <div className="column has-text-centered">
                                    <Link to="/stellenanzeigen?category=category-31617">
                                        <div
                                            className="card card-homepage"
                                            style={{
                                                border: "2px solid #C6C6C6",
                                            }}
                                            id="btn_syscat_alarmanlage"
                                        >
                                            <div
                                                className="card-image"
                                                // style={{ marginTop: "10px" }}
                                            >
                                                <figure className="image">
                                                    <img
                                                        className=" img_1"
                                                        src="/img/carrier/D22Beratung.jpg"
                                                        alt="Alarmgen"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </figure>
                                            </div>
                                            <div
                                                className="card-title"
                                                style={{
                                                    // backgroundColor: "black",
                                                    // backgroundColor: "#F2F2F2",
                                                    // color: "white",
                                                    color: "black",
                                                    paddingTop: "18px",
                                                    // paddingBottom: "42px",
                                                    paddingBottom: "18px",
                                                    height: "150px",
                                                    marginTop: "1px",
                                                    // fontSize: "24px",
                                                }}
                                            >
                                                <h3>
                                                    {" "}
                                                    <b>Sicherheitsberatung</b>
                                                </h3>
                                                <p
                                                    style={{
                                                        marginLeft: "5px",
                                                        marginRight: "5px",
                                                    }}
                                                >
                                                    Werde Teil unseres
                                                    Vertriebsteams und berate
                                                    unsere Kunden rund um das
                                                    Thema Sicherheit.
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="column has-text-centered">
                                    <Link to="/stellenanzeigen?category=category-50864">
                                        <div
                                            className="card card-homepage"
                                            style={{
                                                border: "2px solid #C6C6C6",
                                            }}
                                        >
                                            <div
                                                className="card-image"
                                                // style={{ marginTop: "10px" }}
                                            >
                                                <figure className="image">
                                                    <img
                                                        className=" img_1"
                                                        src="/img/carrier/montage_aussensirene_daitem_d22_web.jpg"
                                                        alt="Alarmgen"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </figure>
                                            </div>
                                            <div
                                                className="card-title"
                                                style={{
                                                    // backgroundColor: "black",
                                                    // backgroundColor: "#F2F2F2",
                                                    // color: "white",
                                                    color: "black",
                                                    paddingTop: "18px",
                                                    // paddingBottom: "42px",
                                                    paddingBottom: "18px",
                                                    height: "150px",
                                                    marginTop: "1px",
                                                    // fontSize: "24px",
                                                }}
                                            >
                                                <h3>
                                                    {" "}
                                                    <b>Sicherheitstechnik</b>
                                                </h3>
                                                <p
                                                    style={{
                                                        marginLeft: "5px",
                                                        marginRight: "5px",
                                                    }}
                                                >
                                                    Installiere hochwertigste
                                                    Sicherheitstechnik bei
                                                    unseren Kunden und sorge
                                                    dafür, dass sie bestens
                                                    abgesichert sind.
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="column has-text-centered">
                                    <Link to="/stellenanzeigen?category=category-231614">
                                        <div
                                            className="card card-homepage"
                                            style={{
                                                border: "2px solid #C6C6C6",
                                            }}
                                            id="btn_syscat_alarmanlage"
                                        >
                                            <div
                                                className="card-image"
                                                // style={{ marginTop: "10px" }}
                                            >
                                                <figure className="image">
                                                    <img
                                                        className=" img_1"
                                                        src="/img/carrier/pexels-andrea-piacquadio-845451.jpg"
                                                        alt="Alarmgen"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </figure>
                                            </div>
                                            <div
                                                className="card-title"
                                                style={{
                                                    // backgroundColor: "black",
                                                    // backgroundColor: "#F2F2F2",
                                                    // color: "white",
                                                    color: "black",
                                                    paddingTop: "18px",
                                                    // paddingBottom: "42px",
                                                    paddingBottom: "18px",
                                                    height: "150px",
                                                    marginTop: "1px",
                                                    // fontSize: "24px",
                                                }}
                                            >
                                                <h3>
                                                    {" "}
                                                    <b>Headquarter</b>
                                                </h3>
                                                <p
                                                    style={{
                                                        marginLeft: "5px",
                                                        marginRight: "5px",
                                                    }}
                                                >
                                                    Wirke im Hintergrund mit und
                                                    unterstütze uns bei unserem
                                                    Ziel, die Nr. 1 für
                                                    Sicherheitstechnik in
                                                    Deutschland zu werden.
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="weSecureMobile only-mobile">
                    <div className="columns">
                        <div className="column has-text-centered">
                            <h2 className="title">
                                Bei uns kannst du in folgenden Bereichen
                                durchstarten:
                            </h2>
                            {/* <h3>Alles für Ihre Sicherheit.</h3> */}
                        </div>
                    </div>

                    <div>
                        <div className="imageRow">
                            <Link to="/stellenanzeigen?category=category-31617">
                                <div
                                    className="imagecontainer"
                                    style={{
                                        border: "2px solid #C6C6C6",
                                    }}
                                >
                                    <div className="weSecureMobile-colsss ">
                                        <h3>
                                            <b>Sicherheitsberatung</b>
                                        </h3>

                                        <p>
                                            Werde Teil unseres Vertriebsteams
                                            und berate unsere Kunden rund um das
                                            Thema Sicherheit.
                                        </p>
                                    </div>
                                    <div
                                        className="weSecureMobile-colz"
                                        // style={{ marginTop: "10px" }}
                                    >
                                        <img
                                            className="weSecureMobileImage2"
                                            src="/img/carrier/D22Beratung.jpg"
                                            alt="Alarmgen"
                                        />
                                    </div>
                                </div>
                            </Link>
                            <Link to="/stellenanzeigen?category=category-50864">
                                <div
                                    className="imagecontainer"
                                    style={{
                                        border: "2px solid #C6C6C6",
                                    }}
                                >
                                    <div className="weSecureMobile-colsss ">
                                        <h3>
                                            <b>Sicherheitstechnik</b>
                                        </h3>

                                        <p>
                                            Installiere hochwertigste
                                            Sicherheitstechnik bei unseren
                                            Kunden und sorge dafür, dass sie
                                            bestens abgesichert sind.
                                        </p>
                                    </div>
                                    <div className="weSecureMobile-colz">
                                        <img
                                            className="weSecureMobileImage2"
                                            src="/img/carrier/montage_aussensirene_daitem_d22_web.jpg"
                                            alt="Videoüberwachung"
                                        />
                                    </div>
                                </div>
                            </Link>
                            <Link to="/stellenanzeigen?category=category-231614">
                                <div
                                    className="imagecontainer"
                                    style={{
                                        border: "2px solid #C6C6C6",
                                    }}
                                >
                                    <div className="weSecureMobile-colsss ">
                                        <h3>
                                            {" "}
                                            <b>Headquarter</b>
                                        </h3>

                                        <p>
                                            Wirke im Hintergrund mit und
                                            unterstütze uns bei unserem Ziel,
                                            die Nr. 1 für Sicherheitstechnik in
                                            Deutschland zu werden.
                                        </p>
                                    </div>
                                    <div className="weSecureMobile-colz">
                                        <img
                                            className="weSecureMobileImage2"
                                            src="/img/carrier/pexels-andrea-piacquadio-845451.jpg"
                                            alt="Videoüberwachung"
                                        />
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div
                    className="centerButtonInDiv "
                    style={{ paddingBottom: "25px" }}
                >
                    <Link
                        className="button preisrechner-btn preisrechner-btn-primary newHome3Button"
                        to="/stellenanzeigen"
                    >
                        Jetzt bewerben
                    </Link>
                </div>
            </div>
        );
    }
}
