import React from "react";
import { Link } from "gatsby";

export default class KarriereComp7 extends React.Component {
    render() {
        let cssClasses =
            "section seven-days" +
            (typeof this.props.cssClass !== "undefined"
                ? " " + this.props.cssClass
                : "");
        let city =
            typeof this.props.city !== "undefined" ? this.props.city : {};
        let phone =
            typeof city.fon !== "undefined" ? city.fon : "040 20 20 50 100";
        let phone_tec =
            "tel://" +
            (typeof city.fon_tec !== "undefined"
                ? city.fon_tec
                : "+040202050100");
        return (
            <div className={cssClasses} style={{ paddingBottom: "3rem" }}>
                <div className="container has-text-centered">
                    <div className="columns">
                        <div className="column title">
                            Du erreichst uns jederzeit!
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column"></div>
                        <div className="column parts">
                            <div className="columns part">
                                <div className="column part-image">
                                    <i className="fas fa-phone"></i>
                                </div>
                                <div className="column">
                                    <p className="title">
                                        <a
                                            id="btn_7days_phone"
                                            href={phone_tec}
                                        >
                                            {phone}
                                        </a>
                                    </p>
                                    <p className="sub-title">
                                        Immer ein offenes Ohr.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="column parts">
                            <div className="columns part">
                                <div className="column part-image">
                                    <i className="fas fa-envelope"></i>
                                </div>
                                <div className="column">
                                    <p className="title">
                                        <a
                                            id="btn_7days_mail"
                                            href="mailto:jobs@meinalarm24.de"
                                        >
                                            jobs@meinalarm24.de
                                        </a>
                                    </p>
                                    <p className="sub-title">
                                        Für alle, die lieber schreiben.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="column parts">
                            <div className="columns part">
                                <div className="column part-image">
                                    <i className="fas fa-reply-all"></i>
                                </div>
                                <div className="column">
                                    <p className="title">
                                        <Link
                                            id="btn_7days_callback"
                                            to="/beratungstermine"
                                        >
                                            Rückruf-Service
                                        </Link>
                                    </p>
                                    <p className="sub-title">
                                        Wir rufen Sie gerne zurück.
                                    </p>
                                </div>
                            </div>
                        </div> */}
                        <div className="column"></div>
                    </div>
                    <div className="columns">
                        <div className="column title">
                            Folge uns für Updates auch in den sozialen
                            Netzwerken
                        </div>

                        {/* <div className="column subtitle">
                            Wir beraten Sie gerne in einem persönlichen Gespräch
                            am Telefon oder bei Ihnen vor Ort.
                        </div> */}
                    </div>
                    <div className="social-icons-style">
                        <a
                            className="fab fa-facebook-square"
                            href="https://www.facebook.com/MeinAlarm24/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#777F8E" }}
                        >
                            &nbsp;
                        </a>
                        <a
                            className="fab fa-linkedin"
                            href="https://www.linkedin.com/company/24776008/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#777F8E" }}
                        >
                            &nbsp;
                        </a>
                        <a
                            className="fab fa-xing-square"
                            href="https://www.xing.com/companies/meinalarm24gmbh"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#777F8E" }}
                        >
                            &nbsp;
                        </a>
                        <a
                            className="fab fa-instagram-square"
                            href="https://www.instagram.com/meinalarm24/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#777F8E" }}
                        >
                            &nbsp;
                        </a>
                        <a
                            className="fab fa-youtube-square"
                            href="https://www.youtube.com/channel/UCJGczjzlw7cb0nFAXBFttyg"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#777F8E" }}
                        >
                            &nbsp;
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
